import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import secondaryImg from "../images/primary-coaches-sm.jpg"
import secondaryImgMob from "../images/primary-coaches-sm-mobile.jpg"
import secondaryImgWebp from "../images/primary-coaches-sm.webp"
import secondaryImgMobWebp from "../images/primary-coaches-sm-mobile.webp"
import coachServicesImg from "../images/coach-hire-services-sm.jpg"
import smileSvg from "../images/smile-beam-regular.svg"
import starSvg from "../images/star-solid.svg"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import NewsStrip from "../components/news-strip";
import AOS from "aos"
import 'aos/dist/aos.css';

class CoachHireHalifaxPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach Hire Halifax" description="Established in 1949, Tetley's Coaches is one of Yorkshire's oldest coach companies and has provided safe and reliable coach transport in Halifax and beyond for over 70 years." />
        <div className="landing-hero-halifax">
          <div className="landing-hero__inner">
            <div className="landing-hero__inner__headline">
              <p className="landing-hero__inner__primary-headline">Reliable, affordable and local</p>
              <p className="landing-hero__inner__secondary-headline">Bus and coach hire since 1949</p>
            </div>
          </div>
        </div>

        <div className="block-about-us body-bg">
          <div className="body">
            <div className="block-about-us__container">
              <h1>Coach Hire Halifax</h1>
              <h2>Providing Coach Hire for over 70 years</h2>
              <div className="gutter block-about-us__content-parent">
                <div className="block-about-us__content-left">
                  <picture className="block-about-us__hero">
                    <source media="(max-width: 499px)" type="image/webp" srcSet={secondaryImgMobWebp} />
                    <source media="(max-width: 499px)" type="image/jpg" srcSet={secondaryImgMob} />
                    <source media="(min-width: 500px)" type="image/webp" srcSet={secondaryImgWebp} />
                    <source media="(min-width: 500px)" type="image/jpg" srcSet={secondaryImg} />
                    <img src={secondaryImg} alt="Coach hire Halifax" />
                  </picture>
                </div>
                <div className="block-about-us__content-right">
                  <p>Tetley's Coaches is one of Yorkshire's oldest, established coach operators and our successful operation continues to provide a comprehensive range of quality and competitively priced transport solutions for any event, whether locally and nationwide.</p>
                  <p>We have seen our client base expand into the wider Yorkshire region of late as our reputation spreads west across the M62 just a short drive from our central Leeds Base; Providing transport for customers such as Lightcliffe Academy, Whitehill Community Academy and many more organisations and groups for one off day trips.</p>
                </div>

              </div>
              <p style={{ textAlign: 'center' }}>
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="block-services">
          <div className="body gutter">
            <div className='block-services__heading' data-aos='fade-left' data-aos-duration="300">
              <h2>Coach hire quote</h2>
              <h3>Coach hire for any occasion</h3>
            </div>
            <div className="block-services__primary">

              <div className="block-services__primary-text">
                <p>Why not explore the city of Halifax by visiting local attractions such as Eureka, Piece Hall and Shibden Hall? Whatever your requirements are our friendly and experienced sales team or on hand ready to help.</p>
                <p>Contact us by email at sales@tetleyscoaches.co.uk, phone on 0113 276 2276 or by completing our <a href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">online quote form</a> for a speedy no-obligation quotation.</p>
              </div>

              <div className="block-services__primary-img">
                <img src={coachServicesImg} alt="" />
              </div>

            </div>

            <div className="block-services__info" data-aos="fade-right" data-aos-duration="600">
              <img className="block-services__info-icon" src={smileSvg} alt='' />
              <div className="block-services__info-text">
                <h4>Unbeaten value</h4>
                <p>If we provide a quote and you can find a similar quality coach cheaper we'll do our best to beat it.</p>
              </div>
            </div>
            <div id="services" className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/airport-transfers/" title="Coach Hire For Airport Transfers">Airport Transfers</Link></li>
                <li><Link to="/coach-hire-services/club-pub-trips/" title="Coach Hire For Club and Pub Trips">Club and Pub Trips</Link></li>
                <li><Link to="/coach-hire-services/coast-country-day-trips/" title="Coach Hire For Coast and Country Day Trips">Coast and Country Day Trips</Link></li>
                <li><Link to="/coach-hire-services/college-university-trips/" title="Coach Hire For College and University Trips">College and University Trips</Link></li>
                <li><Link to="/coach-hire-services/community-groups/" title="Coach Hire For Community Groups">Community Groups</Link></li>
                <li><Link to="/coach-hire-services/contract-coach-hire/" title="Coach Hire For Contract Work">Contract Work</Link></li>
                <li><Link to="/coach-hire-services/corporate-events-coach-hire/" title="Coach Hire For Corporate and Events">Corporate and Events</Link></li>
                <li><Link to="/coach-hire-services/day-at-the-races/" title="Coach Hire For A Day At The Races">Day At The Races</Link></li>
              </ul>
            </div>
            <div className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/wheelchair-disabled-access-coach-hire/" title="Wheelchair friendly and disabled access coach hire">Disabled access coach hire</Link></li>
                <li><Link to="/coach-hire-services/funerals/" title="Coach Hire For Funerals">Funerals</Link></li>
                <li><Link to="/coach-hire-services/group-tours/" title="Coach Hire For Group Tours">Group Tours</Link></li>
                <li><Link to="/coach-hire-services/school-trips/" title="Coach Hire For School Trips">School Trips</Link></li>
                <li><Link to="/coach-hire-services/sporting-events/" title="Coach Hire For Sporting Events">Sporting Events</Link></li>
                <li><Link to="/coach-hire-services/theatre-concerts-cultural-events/" title="Coach Hire For Theatre, Concerts &amp; Cultural Events">Theatre, Concerts &amp; Cultural Events</Link></li>
                <li><Link to="/coach-hire-services/theme-parks/" title="Coach Hire For Theme Parks">Theme Parks</Link></li>
                <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Weddings, Engagements, Parties and Nights Out</Link></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="block-review">
          <div className="body gutter">
            <h2>Reviews</h2>
            <div className="reviews">
              <div className="review" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">Would just like to say a massive thank you from everyone who used the coach on Sunday down to Wealdstone!</p>
                <p className="review-quote">The two drivers were fantastic, couldn't have asked for more! Great coach company and would 100% use again. Once again thank you!</p>
                <p className="review-author">Luke, Halifax</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">I want to pass on my thanks and appreciation to Andrew and Adrian.</p>
                <p className="review-quote">We were attending a family wedding and have never hired a minibus and drivers like this before. The guys were fantastic and nothing was too much trouble during what was a very long day.</p>
                <p className="review-quote">Thank you so much.</p>
                <p className="review-author">Helen, Halifax</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">I just wanted to email and say a big thank you to our driver yesterday.</p>
                <p className="review-quote">He was so helpful and patient with our members and really went out of his way to help and this made such a difference to how much we enjoyed our day out.</p>
                <p className="review-author">Valerie, Halifax</p>
              </div>

            </div>
            <p style={{ margin: 0 }}>
              <a className='btn btn-review btn-grow' href="https://www.google.com/search?q=tetleys+coaches&ie=UTF-8#lrd=0x48795c38882c4475:0x9fc9a7dfa886a150,1,,," target="_blank" rel="noopener noreferrer">
                <span className='btn-review__text'>More reviews</span>
                <img className='btn-review__icon' src={arrowSvg} alt="" />
              </a>
            </p>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default CoachHireHalifaxPage
